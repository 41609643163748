import React from "react";


// THIS COMPONEND IS A CARD WRAPPER FOR GRAY COLOR CARD FOR THE BBPS 
const CardWrapper = ({ className, children }) => {
  return (
    <div
      className={`${className}`}
      style={{
        boxShadow: "0px 3.09579px 12.3831px rgba(17, 34, 17, 0.05)",
      }}
    >
      {children}
    </div>
  );
};

export default CardWrapper;
