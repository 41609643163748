import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   beneficiaryReloader: false,
//   beneficiaryRefetch: null,
//   KYCRefetch: null,
//   KYCRefetchGlobal: null,
//   kycVerified: false,
//   aadhaarVerified: false,
//   panVerified: false,
//   isProfileComplete: false,
//   isReauthenticationRequired: false,
// };

const initialState = {
  beneficiaryReloader: false,           // Boolean flag to determine if beneficiary data needs to be reloaded
  beneficiaryRefetch: null,             // Function reference for refetching beneficiary data, initially set to null
  KYCRefetch: null,                     // Function reference for refetching individual KYC data, initially set to null
  KYCRefetchGlobal: null,               // Function reference for refetching global KYC data, initially set to null
  kycVerified: false,                   // Boolean indicating if the user's KYC verification process is complete
  aadhaarVerified: false,               // Boolean indicating if the user's Aadhaar number has been verified
  panVerified: false,                   // Boolean indicating if the user's PAN has been verified
  isProfileComplete: false,             // Boolean indicating if the user's profile is fully completed
  isReauthenticationRequired: false,    // Boolean indicating if the user needs to go through reauthentication
};
export const paySlice = createSlice({
  name: "pay",
  initialState,
  reducers: {
    updateBeneficiaryReloader(state, action) {
      state.beneficiaryReloader = !state.beneficiaryReloader; // Toggles the beneficiaryReloader flag
    },
    updateBeneficiaryRefetch(state, action) {
      state.beneficiaryRefetch = action.payload.refetch; // Updates the beneficiaryRefetch function reference
    },
    updateKYCRefetch(state, action) {
      state.KYCRefetch = action.payload.refetch; // Updates the KYCRefetch function reference
    },
    updateKYCRefetchGlobal(state, action) {
      state.KYCRefetchGlobal = action.payload.globalRefetch; // Updates the KYCRefetchGlobal function reference
    },
    updateEmployeeDetails(state, action) {
      state.employeeDetails = action.payload.employeeDetails; // Updates the employeeDetails
    },
    updateUserName(state, action) {
      state.userName = action.payload.userName; // Updates the userName
    },
    updateUserId(state, action) {
      state.userId = action.payload.userId; // Updates the userId
    },
    updateKYCVerification(state, action) {
      state.kycVerified = action.payload.kycVerified; // Updates the kycVerified status
    },
    updateAadhaarVerification(state, action) {
      state.aadhaarVerified = action.payload.aadhaarVerified; // Updates the aadhaarVerified status
    },
    updatePANVerification(state, action) {
      state.panVerified = action.payload.panVerified; // Updates the panVerified status
    },
    updateProfileStatus(state, action) {
      state.isProfileComplete = action.payload.profileStatus; // Updates the isProfileComplete status
    },
    updateIsReauthenticationRequired(state, action) {
      state.isReauthenticationRequired = action.payload.isReauthenticationRequired; // Updates the isReauthenticationRequired status
    },
  },
});

