import { useQuery } from "@tanstack/react-query";
import "jspdf-autotable";
import _ from "lodash";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";
// import Loader from "../loader/Loader";
// import FeedBackModal from "../modals/FeedBackModal";
import LoaderForBBPS from "../loader/LoaderForBBPS";
import BBPSPaymentFailure from "./bbpsTransactionStatus/BBPSPaymentFailure";
import BBPSPaymentSuccess from "./bbpsTransactionStatus/BBPSPaymentSuccess";
import PaymentFailure2 from "./PaymentFailure2";
import PaymentProcessing from "./PaymentProcessing";
import PaymentRefund from "./PaymentRefund";

// AFTER THE PAYMENT FOR bbps IS DONE IT COMES TO THIS AS RETURN URL , THEN IT TRIGGERS PROCCESSING PAGE COMPONENT AND THEN IT SHOWS SUCCESS OR FAILURE COMPONENT


const PaymentVErifyWrapperForBBPS = () => {
  const { getAccessToken } = useAuth();
  const { orderkeyid } = useParams();
  const navigate = useNavigate();

  // const [isOpenModal, setIsOpenModal] = useState(false);

  // const [Gateway, setGateway] = useState("razorpay");
  // 

  // IF ORDER KYE IS NOT THERE
  useEffect(() => {
    if (_.isEmpty(orderkeyid)) {
      return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const feedbackTimer = () => {
  //   if (!isOpenModal) {
  //     setTimeout(() => {
  //       setIsOpenModal(true);
  //     }, 3000);
  //   }
  // };

  // useEffect(() => {
  //   feedbackTimer();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

    // FETCH THE ORDER USING THE ORDER ID AND FETCH THE DATA IN API USING REACT USE QUERY
  const { isLoading, isError, data, error, status } = useQuery({
    queryKey: ["fetch_order"],
    queryFn: async () => {
      // const linkss= Gateway === 'airpay' ? APIUrls.airpay_verify : APIUrls.paymentStatus2
      let linkss = APIUrls.cashFreePaymentStatusForBBPS;

      // 
      try {
        const token = await getAccessToken();
        const response = await fetch(linkss, {
          method: "POST",
          body: JSON.stringify({ OrderKeyId: orderkeyid }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const responseJson = await response.json();
        // 
        if (_.isEmpty(responseJson)) {
          return toast.error("Something went wrong !!");
        }

        if (responseJson?.code === 200) {
          // 

          // 

          return responseJson?.response;
          //const message = responseJson.response.message;
        }

        if (responseJson?.code === 400) {
          // const message = responseJson.response.message;
          throw new Error("Something went Wrong ,Try again later !");
        }

        // 
      } catch (error) {
        throw new Error("Something went Wrong ,Try again later !");
      }
    },
    // refetchInterval: 1000,
  });

  // 
  // 

  // if (isLoading) {
  //   // return <span>Loading...</span>;
  //   return <Loader />;
  // }

  if (isError) {
    let status = error.message === "Cancelled" ? 2 : 0;
    //PAYG
    // return <PaymentFailure2 reasonStatus={parseInt(data.OrderStatus)}  keyid={orderkeyid} /> ;

    // AIRPAY
    return (
      <PaymentFailure2 reasonStatus={parseInt(status)} keyid={orderkeyid} />
    );
  }

  return (
    <div className="fixed z-50 bg-black/[0.7] left-0 right-0 bottom-0 top-0  flex justify-center items-center">
      <div className="rounded-[25px] w-[600px] h-[300px] md:w-[800px] md:h-[400px] border-[1px] border-[solid] border-[rgba(255,255,255,0.04)] bg-[#202020] flex flex-col aligns-center justify-center">
        {isLoading && <LoaderForBBPS />}
        <div>
          {status === "success" && (
            <>
              {data.status === "SUCCESS" && (
                // <PaymentSuccess
                //   keyid={orderkeyid}
                //   amount={data.OrderAmount}
                //   orderData={data}
                // />
                <BBPSPaymentSuccess
                  keyid={orderkeyid}
                  amount={data.OrderAmount}
                  orderData={data}
                />
              )}
              {data.status === "FAILED" && (
                // <PaymentFailure
                //   reasonStatus={parseInt(data.OrderStatus)}
                //   keyid={orderkeyid}
                //   amount={data.OrderAmount}
                //   orderData={data}
                // />
                <BBPSPaymentFailure
                  reasonStatus={parseInt(data.OrderStatus)}
                  keyid={orderkeyid}
                  amount={data.OrderAmount}
                  orderData={data}
                />
              )}
              {data.status === "REFUND" && (
                <PaymentRefund
                  reasonStatus={parseInt(data.OrderStatus)}
                  keyid={orderkeyid}
                  amount={data.OrderAmount}
                  orderData={data}
                  refundStatus={data?.refundStatus}
                />
              )}
              {(data.status === "PROCESSING" || data.status === "PENDING") && (
                <PaymentProcessing
                  reasonStatus={parseInt(data.OrderStatus)}
                  keyid={orderkeyid}
                  amount={data.OrderAmount}
                  orderData={data}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentVErifyWrapperForBBPS;
