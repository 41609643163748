import {
  RecaptchaVerifier,
  reauthenticateWithPhoneNumber,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithPhoneNumber,
  signOut,
  updateProfile,
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { auth } from "../Firebase";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { APIUrls } from "../baseUrl/BaseUrl";
import { fetchUser, generateReferal } from "./UserRedux";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(); // State to store the current authenticated user
  const [userRole, setUserRole] = useState(); // State to store the user's role
  const [loading, setLoading] = useState(true); // State to handle loading status
  const [recaptchaVerifier, setrecaptchaVerifier] = useState({}); // State to store the reCAPTCHA verifier instance
  const [confirmationResult, setConfirmationResult] = useState(); // State to store the result of phone number confirmation
  const [refresh, setrefresh] = useState(0); // State to trigger user data refresh
  const [walletLimitData, setWalletlimitdata] = useState(0); // State to store wallet limit data
  const [warnModelOn, setWarnModel] = useState(false); // State to handle warning model
  const [gatewayset, SetGateway] = useState("airpay"); // State to store payment gateway settings

  const dispatch = useDispatch(); // Hook to access the Redux dispatch function

  // Function to setup reCAPTCHA verifier for phone authentication
  let recaptchaVerifier2 = null;
  const setupRecaptcha = (phone) => {
    if (_.isEmpty(recaptchaVerifier)) {
      recaptchaVerifier2 = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );
      setrecaptchaVerifier(recaptchaVerifier2);
      recaptchaVerifier2.render();
      return recaptchaVerifier2;
    }
    return recaptchaVerifier;
  };

  // Function to sign out the user
  function logout() {
    return signOut(auth);
  }

  // Function to update the current user's data from Firebase
  const updateUsers = async () => {
    const user = auth.currentUser;
    await user.reload();
    setCurrentUser(user);
  };

  // Function to login using phone number
  async function login(phoneNumber) {
    const res = setupRecaptcha(phoneNumber);
    return await signInWithPhoneNumber(auth, phoneNumber, res);
  }

  // Function to login using a custom token
  async function customLogin(token) {
    return await signInWithCustomToken(auth, token);
  }

  // Function to send password reset email
  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  // Function to update user information (uid and role)
  function updateUser(uid, role) {
    return updateUser(uid, role);
  }

  // Function to update email of the current user
  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  // Function to update password of the current user
  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  // Function to get the access token of the current user
  function getAccessToken() {
    return currentUser?.getIdToken(true);
  }

  // Function to get the current user
  function getCurrentUser() {
    return currentUser.getUser();
  }

  // Function to save the user's role
  function saveUserRole(value) {
    setUserRole(value);
  }

  // Function to update profile picture of the current user
  function updateProfilePicture(photoURL) {
    return updateProfile(currentUser, { photoURL });
  }

  // Function to re-authenticate the user with phone number
  function reAuthenticate(phoneNumber) {
    return reauthenticateWithPhoneNumber(currentUser, phoneNumber);
  }

  // Function to refresh the user data
  function re_fresh_user_data() {
    return setrefresh(refresh + 1);
  }

  // Function to fetch wallet limit data
  const fetch_wallet_limit = async (tokens) => {
    setLoading(true);
    try {
      const token = tokens;
      const response = await fetch(APIUrls.get_wallet_limit, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request header
        },
      });
      const limitData = await response.json();
      setWalletlimitdata(limitData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  // Function to get wallet limit data
  function get_wallet_lim() {
    return walletLimitData?.response;
  }

  // Function to get settings data
  const get_settings = async () => {
    setLoading(true);
    try {
      const response = await fetch(APIUrls.get_settingsData);
      const limitData = await response.json();
      const pay_status = limitData?.payment_status;
      const gatew = limitData?.gateway;
      setWarnModel(!pay_status);
      SetGateway(gatew);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  // Effect to handle auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      get_settings();
      const temp_token = user?.accessToken;
      if (!_.isEmpty(temp_token)) {
        await fetch_wallet_limit(temp_token);
        dispatch(fetchUser(temp_token));
        dispatch(generateReferal(temp_token));
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [dispatch, refresh]);

  // Auth context value
  const value = {
    currentUser,
    setupRecaptcha,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    getAccessToken,
    updateUser,
    getCurrentUser,
    userRole,
    saveUserRole,
    confirmationResult,
    setConfirmationResult,
    updateProfilePicture,
    reAuthenticate,
    re_fresh_user_data,
    updateUsers,
    get_wallet_lim,
    warnModelOn,
    gatewayset,
    customLogin,
  };

  // Auth context provider
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
