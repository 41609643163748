import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIUrls } from "../baseUrl/BaseUrl";

const initialState = {
  emailVerified: false, // THIS IS TO CHECK EMAIL VERIFIED OR NOT 
  kyc_verified: false, // THIS IS TO CHECK KYC VERIFIED 
  paymentDisabled: true, // THIS IS TO CHECK PAYMENT DISABLED 
  userLoading: false, // THIS IS FOR LOADING INSIDE THE AUTH CONTEXT 
 
  referal_url: "", // TO STORE THE URAL OF USER REFERAL 
  referal_code: "", // TO STORE THE CODE OF REFERAL CODE  
  earning_total: "", // EARNING TOTAL OF THE USER REFFERAL
  withdrawal_arrays: [], // TOTAL WITHDRAWAL RECORDS FETHED FORM THE DATABASE 
  earning_arrays: [], // EARNING RECORDS OF THE USER 
  transaction_arrays: [], // TRANSACTION RECORDS OF THE USER 
  UserBlockIp: false, // THS IS TTO CHECK IF THE USERS IP IS BLOCKED BY THE SYSTEM
};

export const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {

    // ACTION FOR EMAIL VERIFY 
    updateEmailVerified(state, action) {
      state.emailVerified = action.payload;
    },

    // ACTION FOR THE UPDATE KYC 
    updatekyc_verified(state, action) {
      state.kyc_verified = action.payload;
    },

    // ACTION FOR THE UPDATE PAYMENT DISABLE STATUS 
    updatePaymentDisabled(state, action) {
      state.paymentDisabled = action.payload;
    },
  

    // ACTION TO UPDATE THE REFERAL URL
    updateRefralURL(state, action) {
      state.referal_url = action.payload;
    },

    // ACTION TO UPDATE THE REFERAL CODE 
    updateRefralCode(state, action) {
      state.referal_code = action.payload;
    },

     // ACTION TO  UPDATE THE EAR NING ARRAY 
    updateEarning(state, action) {
      state.earning_total = action.payload;
    },
 

    // ACTION TO UPDATE THE WITHDRAWALL LIST 
    updateWithdrawalList(state, action) {
      state.withdrawal_arrays = action.payload;
    },

    // ACTION TO UPDATE HTE EARNING LIST
    updateEarningList(state, action) {
      state.earning_arrays = action.payload;
    },

    // ACTION UPDATE THE TRASACTION LIST
    updateTransactionsList(state, action) {
      state.transaction_arrays = action.payload;
    },


    // ACTION TO UPDATE THE LOADER 

    setLoading(state, action) {
      state.userLoading = action.payload;
    },


    // ACTION TO SHOW THE IP BLOCK SCREEN
    setUserBlock(state, action) {
      state.UserBlockIp = action.payload;
    },
  },
});


// EXPORTING THE ACTIONS 

const {
  setLoading,
  updateEmailVerified,
  updatekyc_verified,
  updatePaymentDisabled,
  updateRefralCode,
  updateRefralURL,
  updateEarning,
  updateWithdrawalList,
  updateEarningList,
  updateTransactionsList,
  // eslint-disable-next-line no-unused-vars
  setUserBlock,
} = userSlice.actions;

// Create a thunk action creator TO FETCH THE USER 
export const fetchUser = (tokens) => {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      };
      // Dispatch a loading action to indicate the start of the request
      dispatch(setLoading(true));

      // Make an asynchronous API request
      await axios
        .get(APIUrls.get_userData, config)
        .then((data) => {
          dispatch(setLoading(false));

          const datas = data.data;

          //

          if (data.status === 200) {
            const {
              kyc_status,
              paymentDisabled,
              emailVerified,
              total_earnings,
              withdrawals,
              EarningsArray,
              transactions,
            } = datas?.response?.UserData;

            //

            //
            //
            //
            //
            // SET ALL THE DATA FETCHED FROM THE USER DETAILS API

            dispatch(updateEmailVerified(emailVerified));
            dispatch(updatekyc_verified(kyc_status));
            dispatch(updatePaymentDisabled(paymentDisabled));
            dispatch(updateEarning(total_earnings));
            dispatch(updateWithdrawalList(withdrawals));
            dispatch(updateEarningList(EarningsArray));
            dispatch(updateTransactionsList(transactions));
          }

          if (data.status !== 200) {
            //
          }

          //
        })
        .catch((error) => {
          dispatch(setLoading(false));

          //
        });

      // Dispatch a success action with the fetched data
      //dispatch(setUser(response.data));
    } catch (error) {
      dispatch(setLoading(false));
      // Dispatch an error action if the request fails
      //dispatch(setError(error.message));
      //
    }
  };
};

// Create a thunk action creator
export const generateReferal = (tokens) => {
  return async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      };
      // Dispatch a loading action to indicate the start of the request
      dispatch(setLoading(true));

      // Make an asynchronous API request
      await axios
        .get(APIUrls.generate_referals, config)
        .then((data) => {
          dispatch(setLoading(false));

          const datas = data.data;

          //

          if (data.status === 200) {

            // FETCH THE DATA FROM USERS AND SET THE REFERAL URL AND CODE 
            const referal_data = datas.response;

            dispatch(updateRefralURL(referal_data?.referal_url));
            dispatch(updateRefralCode(referal_data?.referal_code));
          }

          if (data.status !== 200) {
            //
          }

          //
        })
        .catch((error) => {
          dispatch(setLoading(false));

          //
        });

      // Dispatch a success action with the fetched data
      //dispatch(setUser(response.data));
    } catch (error) {
      dispatch(setLoading(false));
      // Dispatch an error action if the request fails
      //dispatch(setError(error.message));
      //
    }
  };
};
