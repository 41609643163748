import React from "react";

// THIS COMPONENT CONSIST OF THE INPUT FIELD LAVELS WITH FONT USED ALL OVER THE APP

const InputFieldLabel = ({ htmlFor = "", label = "", classNames = "" }) => {
  return (
    <label
      htmlFor={htmlFor}
      className={
        "block mb-[16px] text-[#FFF] font-[Inter] text-[16px] xs:text-[20px] not-italic font-bold leading-[normal] " +
        classNames
      }
    >
      {label}
    </label>
  );
};

export default InputFieldLabel;
