import { Icon } from "@iconify/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import failed from "../../assets/failure.svg";
import CardWrapper from "./CardWrapper";
import { ThreeCircles } from "react-loader-spinner";
import "@lottiefiles/lottie-player";



// AAFTER WE RETURNED THE WEBSITE WHEN PAYMENT IS DONE AND PAYMENT GATEWAY REDIRECTS TO THE RETURN URL THIS SCREEN IS BEING SHOWN TO THE USER SOWING THE PAYMENT IS IN PROCCESS BECAUSE IT TAKES FEW SECONDS FOR WEBHOOK TO TRIGGER.
const PaymentProcessing = (props) => {
  const navigate = useNavigate();
  const goBack = (e) => {
    e.preventDefault();
    navigate("/profile/my-transactions");
  };
  return (
    <CardWrapper
      className={
        "px-[1rem] bg-[#F9F9F9] font-inter rounded-[12px] md:rounded-[25px] xs:max-sm:px-[1.5rem] sm:max-md:px-[2.5rem] md:px-[1rem] lg:px-[1.5rem] xl:px-[2.5rem] flex flex-col justify-center gap-[0px] sm:gap-[2rem] py-[1rem] sm:py-[2rem] min-h-[420px] xs:min-h-[500px]"
      }
    >
      <div className="flex flex-col md:flex-row  md:items-center md:justify-center px-[0px] sm:px-[40px] lg:px-[60px] xl:px-[80px] gap-[20px] md:gap-[40px] lg:gap-[60px] xl:gap-[80px] ">
        <div className="">
          <div className="flex justify-center ">
            {/* <ThreeCircles
              visible={true}
              height="100"
              width="100"
              color="#FFFF00"
              ariaLabel="three-circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
            /> */}
            <lottie-player
              src="https://lottie.host/a913fa99-b6f0-446e-88f9-50e089fa9ed9/YQTYVp66TK.json"
              background="transparent"
              color="#F78500"
              speed="1"
              style={{ width: "300px", height: "150px" }}
              // style={{  }}
              loop
              autoplay
            ></lottie-player>
          </div>

          <div className="text-center">
            <p className="text-[#F78500] text-center font-[Inter] mb-[16px] text-[16px] sm:text-[20px] xl:text-[24px] not-italic font-bold leading-[normal]">
              Your payment of ₹{props.amount || 0} was Processing.
            </p>

            <p className="text-[#FFF] text-center mb-[14px] sm:mb-[16px] lg:mb-[32px]  font-[Inter] text-[14px] sm:text-[16px] xl:text-[20px] not-italic font-bold leading-[normal]">
              <span className="text-[#000] font-[Inter]  not-italic font-semibold leading-[normal]">
                Transaction ID:{" "}
              </span>
              <span className="text-[rgba(0,_0,_0,_0.60)]">
                {props.keyid || "N/A"}
              </span>
            </p>
          </div>

          <button
            className="  bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)]
            text-center  font-[Inter] w-full text-[16px] sm:text-[20pxrem] rounded-[30px] text-white py-[0.8rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]"
            onClick={(e) => goBack(e)}
          >
            <span className="text-[16px] sm:text-[20px]">HOME</span>
          </button>

          {/* </button> */}
        </div>
      </div>
    </CardWrapper>
  );
};

export default PaymentProcessing;
