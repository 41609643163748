// THIS CONSISTS OF ALL THE BBPS ERROR STATUS CODEDS FROM THE BACKEND BASED ON THAT WE HAVE CUSTOM MESSAGES


export function getBillFetchErrorMsg(errorCode) {
  const errorMessages = {
    BFR001: "Incorrect / invalid Customer account",
    BFR002: "Invalid combination of Customer parameters",
    BFR003: "No bill data available",
    BFR004: "Payment received for the billing period - no bill due",
    BFR005: "Customer account is blocked / closed",
    BFR006: "Customer account is not activated",
    BFR007: "Bill due date has expired - bill details are not available",
    BFR008: "Unable to get bill details from Biller",
    BFR009:
      "Scheduled downtime taken by biller for maintenance activity. Please try again later",
    BFR010:
      "Unscheduled downtime taken by biller for maintenance activity. Please try again later",
    BFR011: "Incomplete details in Biller system - update Customer profile",
    BFR012: "ePayment not enabled for the dealer",
    BFR013:
      "Maximum Refill Count Has Been Reached. {schemename} consumers can get maximum {RefillPerYear} refills in a year.",
    BFR014: "This consumer has reported loss of cylinder",
    BFR015: "Can not take booking as the consumer not yet submitted KYC.",
    BFR016: "One prior booking is pending against this Consumer",
    BFR017:
      "Price not yet set for Nature code - {NatureCode} and Package Code - {ItemDescription}",
    BFR018: "Day-End not done. Please try after some time",
    BFR019: "Consumer No. and Distributor not matching",
    BFR020: "LPGId not found",
    BFR021: "Vehicle Registered Number entered is not exists or Invalid",
    BFR022: "Entered Fastag is Inactive/blocked. Recharge not allowed",
    BFR023: "Entered Fastag is exempted. Recharge not allowed",
  };

  return errorMessages[errorCode] || "Failed to fetch bill.Try Again";
}

export function getBillValidationErrorMsg(errorCode) {
  const errorMessages = {
    BVR001: "Incorrect / invalid Customer account",
    BVR002: "Invalid combination of Customer parameters",
    BVR003: "Customer account is blocked / closed",
    BVR004: "Customer account is not activated",
    BVR005: "Invalid Amount",
    BVR006: "Customer account deactivated - pay Rs. <xxx> to activate account",
    BVR007: "Incomplete details in Biller system - update Customer profile",
    BVR008: "Customer account valid but no bill due",
    BVR009: "Technical exception from biller",
  };

  return errorMessages[errorCode] || "Failed to validate bill.Try Again";
}
