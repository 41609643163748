import { Icon } from "@iconify/react";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import cross from "../../../src/assets/revamped/Cross.svg";
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";
import { fetchUser } from "../../stores/UserRedux";
import Modal from "./Modal";

// THIS COMPONENT CONSIST OF THE MODAL FOR SENDING AND VERIFY EMAIL OTP

const EmailOTP = ({
  isOpenModal,
  setIsOpenModal,
  otp_uuid,
  email,
  SendVerify,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [isSecondOpen, setIsSecondOpen] = useState(false);
  const [isThirdOpen, setIsThirdOpen] = useState(false);
  const { getAccessToken, updateUsers } = useAuth();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [isOtpError, setOtpError] = useState(false);
  const navigate = useNavigate();
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [timer, setTimer] = useState(60);


  // EXTRACT REACT FORM DATA FROM REACT FORM HOOK
  const {
    // register,
    // handleSubmit,
    formState: {
      //  errors,
      isValid,
    },
    // control,
    // reset,
  } = useForm();


  // THIS FUNCTION IS USED TO SUBMIT THE OTP 
  const submitOTPs = async () => {
    //

    if (_.isEmpty(otp)) {
      return displayError("OTP is required");
    }

    try {
      setIsLoading(true);

      const token = await getAccessToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
     // SUBMIT OTP EMAIL API HERE 
      await axios
        .post(
          APIUrls.submit_otp_email,
          {
            otp,
            uu_id: otp_uuid,
          },
          config
        )
        .then(async (data) => {
          setIsLoading(false);
          const { code, message } = data.data;

          if (data.status === 200) {
            if (code === 200) {
              setIsOpenModal(false);

              toast.success("Email has been verified successfully");
         

              // IF OTP SUBMIT SUCCESS 

              // UPDATE THE USER API
              await updateUsers();
         

              // CALL FETCH USER API FROM REDUX STORE USERREDUX
              dispatch(fetchUser(token));
           
              // NAVIAGET TO HOMEPAGE
              return navigate("/");
            }
          }

          if (data.status === 400) {
            if (code === 400) {
              displayError(message);
              return toast.error(message);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          setIsOpenModal(false);
          return toast.error("Something went wrong Please try again later !");
        });
    } catch (errr) {
      setIsLoading(false);

      setIsOpenModal(false);
      return toast.error("Something went wrong Please try again later !");
    }
  };

  //

  const handleGoBack = () => {
    if (isThirdOpen) {
      setIsThirdOpen(false);
      setIsSecondOpen(true);
    } else if (isSecondOpen) {
      setIsSecondOpen(false);
      setIsThirdOpen(false);
      // setIsFirstOpen(true);
    }
  };

  // const getOTPHandler = (data, event) => {
  //   // setIsFirstOpen(false);
  //   setIsThirdOpen(false);
  //   setIsSecondOpen(true);
  // };

  // const submitOTP = (data, event) => {
  //   // setIsFirstOpen(false);
  //   setIsSecondOpen(false);
  //   setIsThirdOpen(true);
  // };

  const individualInputStyle = {
    width: "54px",
    height: "50px",
    fontSize: "19px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",

    outline: "none",
    margin: "auto",
    fontStyle: "bold",
    background: "#202020",
    color: "white",
  };


  // THIS FUNCTION IS TO DISPLAY ERROR 
  const displayError = (text) => {
    setOtpErrorMsg(text);
    setOtpError(true);
  };


  // THIS USEEFFECT IS TO SHOW TIMER COUNTDOWN AFTER EMAIL OTP IS SENT , EACH 30 SECONDS COUNTDOWN WHEN YOU CAN RESEND OTP
  useEffect(() => {
    let intervalId;

    if (isSecondOpen && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal, timer]);

  return (
    <Modal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}>
      <div className="w-[95%] m-auto md:min-w-[800px] md:min-h-[433px] bg-[#202020] rounded-[12px] p-[40px]  md:p-[80px] flex flex-col gap-[1rem] relative max-h-[85vh] overflow-y-auto">
        {/* <Icon
          icon="radix-icons:cross-circled"
          className="absolute right-[2rem] top-[1rem] text-[1.5rem] cursor-pointer"
          onClick={() => {
            setIsOpenModal(false);
          }}
        /> */}
        <img
          src={cross}
          alt=""
          className=" absolute right-[2rem] top-[1rem] text-[1.5rem] cursor-pointer"
          onClick={() => {
            setIsOpenModal(false);
          }}
        />

        <div className="pb-[1rem] flex flex-row gap-[1rem] items-center">
          {isSecondOpen || isThirdOpen ? (
            <Icon
              icon="octicon:arrow-left-16"
              className="text-[1.5rem] cursor-pointer"
              onClick={() => {
                handleGoBack();
              }}
            />
          ) : null}

          <p className="text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] md:text-[20px] not-italic font-medium leading-[normal]">
            An OTP has been sent to{" "}
            <span className="text-[#FFF] ">&nbsp;{email}</span>
          </p>
        </div>
        <div className="flex flex-row justify-center w-full">
          <form
            // action=""
            // onSubmit={handleSubmit(addBenificer)}
            className="flex flex-col gap-[40px] w-full "
          >
            <div className=" flex flex-col gap-[0.2rem]">
              <div className="flex  flex-col gap-[16px] ">
                <label
                  htmlFor="beneficiary_phone"
                  className="text-[#8A8A8A]  font-bold gap-[0.8rem] md:mb-5"
                >
                  Enter your 6 digit OTP
                </label>
                <OtpInput
                  value={otp}
                  onChange={(e) => setOtp(e)}
                  numInputs={6}
                  renderSeparator={<span>&nbsp; &nbsp;</span>}
                  inputType="text"
                  inputStyle={individualInputStyle}
                  renderInput={(props) => <input {...props} />}
                  className="mb-5"
                />
                <div className="flex ">
                  {timer > 0 ? (
                    <p className="text-[#FFF] font-[Inter] text-[14px] md:text-[16px] not-italic font-semibold leading-[normal] [text-decoration-line:underline]">
                      Resend OTP in {timer} sec{" "}
                    </p>
                  ) : (
                    <button
                      type="button"
                      className="text-[#00006B] text-[0.8rem] xs:text-[1rem] font-bold"
                      onClick={(e) => {
                        SendVerify(e);
                      }}
                    >
                      Resend OTP
                    </button>
                  )}
                </div>

                {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                {isOtpError && (
                  <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    <span>{isOtpError ? otpErrorMsg : ""}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                disabled={isLoading}
                className={`${
                  !isValid || isLoading
                    ? "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                    : "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                } text-center w-full  rounded-[8px] text-white py-[0.7rem] px-[6rem] font-[800] flex flex-row items-center  justify-center gap-[0.6rem]`}
                onClick={() => submitOTPs()}
              >
                {isLoading && (
                  <CgSpinner
                    size={`${window.innerWidth < 768 ? 10 : 20}`}
                    className="mt-1 animate-spin"
                  />
                )}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default EmailOTP;
