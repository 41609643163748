import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function RefundPolicy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="font-inter text-white ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Refund Policy</title>
        <link rel="canonical" href="https://payinstacard.com/refund-policy" />
      </Helmet>

      <div
        className="flex flex-col  lg:flex-row justify-center mt-[1rem] xs:mt-[2.5rem] lg:max-w-[1024px] xl:max-w-[1280px] max-w-[640px] sm:mx-auto mx-[1rem]   py-[1rem] lg:py-[2rem] pl-[1.5rem]   lg:pl-[2.5rem]"
        style={{
          borderRadius: "16px",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(90deg, #F4795A 0.04%, #F4705C 15.04%, #F55960 38.04%, #F73367 67.04%, #F90070 100.04%)",
        }}
      >
        <div className="text-center text-white flex flex-col items-center gap-[1rem] ">
          <h1 className="lg:text-4xl md:text-3xl xl:text-[3rem] text-2xl font-bold">
            Refund Policy
          </h1>
          <p className="lg:text-4sm md:text-3sm xl:text-[1rem] sm:text-2sm">
            Last Updated on 14th June 2023
          </p>
        </div>
      </div>
      {/* <div className="p-14 xs:mt-5 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]">
        <div className="text-center text-white flex flex-col gap-[1rem]">
          <h1 className="lg:text-4xl md:text-3xl xl:text-[3rem] text-2xl font-bold">
            Refund Policy
          </h1>
          <p className="lg:text-4sm md:text-3sm xl:text-[1rem] sm:text-2sm">
            Last Updated on 14th June 2023
          </p>
        </div>
      </div> */}

      <div className="px-4 py-8 max-w-[1320px] w-full mx-auto leading-10 flex flex-col gap-[1rem]">
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">
            Refund Policy for PayInstaCard Private Limited: Transactions and
            Settlements
          </h2>
          <p>
            At PayInstaCard Private Limited, we are committed to providing
            transparent and fair refund policies to ensure a positive experience
            for our customers using our payment and settlement services and all
            the other services provided by Payinstacard. Please review the
            following refund policy details:
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">Refund Eligibility:</h2>
          <ul className="list-disc pl-6">
            <li>
              Refunds are applicable only in specific circumstances and are
              subject to verification and approval by PayInstaCard.
            </li>
            <li>
              Refunds will be considered for accidental or erroneous
              transactions, duplicate payments, or other valid reasons at the
              discretion of PayInstaCard.
            </li>
          </ul>
        </div>
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">Refund Process:</h2>
          <ul className="list-disc pl-6">
            <li>
              To initiate a refund request, customers must contact
              PayInstaCard's customer support within 1day, from the date of the
              transaction.
            </li>
            <li>
              Refund requests should include relevant transaction details, such
              as payment ID, date, and amount, as well as a clear explanation of
              the reason for the refund request.
            </li>
            <li>
              PayInstaCard reserves the right to request additional information
              or documentation to verify the refund request.
            </li>
          </ul>
        </div>
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">
            Non-Refundable Transactions:
          </h2>
          <ul className="list-disc pl-6">
            <li>
              Transactions that have been successfully processed and settled to
              the beneficiary's account are generally non-refundable, except in
              cases of proven error or exceptional circumstances.
            </li>
            <li>
              Fees associated with Transactions and settlements, including
              service charges or processing fees, are non-refundable.
            </li>

            <li>
              Refund Approval and Processing: Once a refund request is received
              and verified, PayInstaCard will review the request and determine
              whether it meets the eligibility criteria. Approved refunds will
              be processed using the same payment method used for the original
              transaction. If this is not feasible, alternative refund methods
              may be considered. Refunds may take up to 7 to 10 business days to
              be processed, depending on the
            </li>
          </ul>
        </div>

        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">
            Refund Approval and Processing:
          </h2>
          <ul className="list-disc pl-6">
            <li>
              Once a refund request is received and verified, PayInstaCard will
              review the request and determine whether it meets the eligibility
              criteria.
            </li>
            <li>
              Approved refunds will be processed using the same payment method
              used for the original transaction. If this is not feasible,
              alternative refund methods may be considered.
            </li>
            <li>
              Refunds may take up to 7 to 10 business days to be processed,
              depending on the payment method and financial institution's
              processing timeline.
            </li>

            <li>
              A 2% Refund Processing Fee (plus taxes) will be charged for every
              Refund processed.
            </li>
          </ul>
        </div>

        {/* ... other sections ... */}

        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">Disputed Transactions:</h2>
          <p className="mb-4">
            In cases of any unauthorized or disputed transactions, customers are
            advised to follow the standard dispute resolution procedures
            provided by their credit card issuer or financial institution.
            PayInstaCard will cooperate with relevant parties to resolve
            disputes promptly.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">Policy Modifications:</h2>
          <p className="mb-4">
            PayInstaCard reserves the right to modify or update this refund
            policy at any time. Any changes to the policy will be communicated
            to customers through our website or other appropriate channels.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">Contact Information:</h2>
          <p className="mb-1">
            For refund requests, inquiries, or assistance, please contact
            PayInstaCard's customer support at:
          </p>
          <p className="mb-1">
            Email id:{" "}
            <a href="mailto:support@payinstacard.com">
              support@payinstacard.com
            </a>
          </p>
          <p className="mb-1">
            Contact: <a href="tel:+919100420520">+91 9100420520</a>
          </p>
          <p>
            Address: H NO 5-729, B-BLOCK NO6, IZZATH NAGAR, SERLINGAMPALLY,
            5/1/WEAKER SECTION COLONY HYDERABAD Rangareddi TG 500084 IN.
          </p>
          <p>Sai Krishna Musunuru, Chief Risk Officer</p>
        </div>

        <p className="text-sm">
          By using the PayInstaCard platform, customers agree to abide by this
          refund policy and acknowledge that PayInstaCard's decision regarding
          refunds is final. This policy is designed to uphold our commitment to
          fairness and transparency while providing a reliable and efficient
          payment and settlement service.
        </p>
      </div>
    </div>
  );
}

export default RefundPolicy;
