import React, { Suspense, useEffect, useState } from "react";
import {
  Navigate,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
// import { useAuth } from "../stores/AuthContext";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { APIUrls } from "../baseUrl/BaseUrl";
import Loader from "../components/loader/Loader";
import AboutUs from "../components/policies/AboutUs";
import PrivacyPolicy from "../components/policies/PrivacyPolicy";
import RefundPolicy from "../components/policies/RefundPolicy";
import { useAuth } from "../stores/AuthContext";

import { useQuery } from "@tanstack/react-query";
import Ipblocklayout from "../Ipblocklayout";
import BBPSServices from "../components/bbpsComponents/BBPSServices";
import HomePage from "../components/cards/HomePage";
import PaymentVErifyWrapperForBBPS from "../components/cards/PaymentVErifyWrapperForBBPS";
import RentCards from "../components/cards/RentalDropdownMobile";
import UtilityWrapper from "../components/cards/UtilityWrapper";
import ComePage from "../components/cards/payment/ComePage";
import ReferralProgram from "../components/profiles/ReferralProgram";
import LifeInsuranceService from "../components/serviceComponent/LifeInsuranceService";
import MobilePostpaidService from "../components/serviceComponent/MobilePostpaidServices";
import WaterService from "../components/serviceComponent/WaterService";
import CreditCardService from "../components/serviceComponent/finance/Credit Card/CreditCardService";
import Finance from "../components/serviceComponent/finance/Finance";
import LoanRepaymentService from "../components/serviceComponent/finance/Loan Repayment/LoanRepaymentService";
import FasTagService from "../components/serviceComponent/recharge/Fastag/FasTagService";
import Recharge from "../components/serviceComponent/recharge/Recharge";
import ElectricityService from "../components/serviceComponent/utility/Electricity/ElectricityService";
import LPGGasService from "../components/serviceComponent/utility/LPG Gas/LPGService";
import UtilityCard from "../components/serviceComponent/utility/UtilityCard";
import HomeLayout from "../layouts/HomeLayout";
import { payActions } from "../stores/IndexRedux";
import { userActions } from "../stores/IndexRedux";
import EdutionCards from "../components/cards/EducationCards";
import PaymentCardForEducation from "../components/cards/payment/PaymentCardForEducation";
import InsuranceService from "../components/serviceComponent/finance/Insurance/InsuranceService";
import NCMCRecharge from "../components/serviceComponent/recharge/NCMC Recharge/NCMCRecharge";
import BBPSEducation from "../components/serviceComponent/utility/BBPSEducation/BBPSEducation";
import axios from "axios";

const LandingPage = React.lazy(() => import("../pages/LandingPage"));
const MainLayout = React.lazy(() => import("../layouts/MainLayout"));
const LoginWrapper = React.lazy(() =>
  import("../components/cards/login/LoginWrapper")
);
const Login = React.lazy(() => import("../components/cards/login/Login"));
const LoginOTPSubmit = React.lazy(() =>
  import("../components/cards/login/LoginOTPSubmit")
);
const CategorySelectionCard = React.lazy(() =>
  import("../components/cards/CategorySelectionCard")
);
const PaymentWrapper = React.lazy(() =>
  import("../components/cards/payment/PaymentWrapper")
);
// const PaymentRentWrapper = React.lazy(() =>
//   import("../components/cards/payment/PaymentRentWrapper")
// );

const PaymentCard = React.lazy(() =>
  import("../components/cards/payment/PaymentCard")
);

const Contactus = React.lazy(() => import("../pages/Contactus"));
const PaymentSuccess = React.lazy(() =>
  import("../components/cards/PaymentSuccess")
);
const PaymentFailure = React.lazy(() =>
  import("../components/cards/PaymentFailure")
);
const MainProfilePage = React.lazy(() => import("../pages/MainProfilePage"));
const MyProfile = React.lazy(() => import("../components/profiles/MyProfile"));
const Mytransaction = React.lazy(() =>
  import("../components/profiles/Mytransaction")
);
const KYCVerification = React.lazy(() =>
  import("../components/profiles/KYCVerification")
);
const AllTransactions = React.lazy(() =>
  import("../components/profiles/AllTransactions")
);
const HouseRentTransactions = React.lazy(() =>
  import("../components/profiles/HouseRentTransactions")
);
const OfficeRentTransactions = React.lazy(() =>
  import("../components/profiles/OfficeRentTransactions")
);
const MaintenanceTransactions = React.lazy(() =>
  import("../components/profiles/MaintenanceTransactions")
);
const EducationFeeTransactions = React.lazy(() =>
  import("../components/profiles/EducationFeeTransactions")
);
const Beneficiary = React.lazy(() =>
  import("../components/profiles/Beneficiary")
);
const Error404 = React.lazy(() => import("../pages/Error404"));
const Terms = React.lazy(() => import("../components/policies/Terms"));
const PrepaidCars = React.lazy(() =>
  import("../components/policies/PrepaidCars")
);

const PaymentVErifyWrapper = React.lazy(() =>
  import("../components/cards/PaymentVerifyWrapper")
);
const PaymentCardUtilsimport = React.lazy(() =>
  import("./../components/cards/payment/PaymentCardUtils")
);
const ComingSoon = React.lazy(() => import("../pages/ComingSoon"));

const Referralmainpage = React.lazy(() => import("../pages/Referralmainpage"));

const Earnings = React.lazy(() => import("../components/profiles/Earnings"));

const PrivateRoute = ({ component: Component }) => {
  const [path, setPath] = useState();
  // const [isLoading, setIsLoading] = useState();
  const { isProfileComplete, kycVerified } = useSelector((state) => state.pay);
  const location = useLocation();
  const { currentUser, getAccessToken } = useAuth();
  // const [statusCode, setStatusCode] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userblockstate = useSelector((state) => state.userData.UserBlockIp);
  const { refetch, data, status } = useQuery({
    queryKey: ["kycDetailsGlobalKey"],
    queryFn: async () => {
      try {
        if (!currentUser) {
          return;
        }
        const token = await getAccessToken();
        const response = await fetch(APIUrls.fetchKYCDetails, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const responseJson = await response.json();
        //
        // setStatusCode(responseJson?.code);
        if (_.isEmpty(responseJson)) {
          toast.error("Something went wrong !!");
          throw new Error("Something went wrong !!");
        } else if (responseJson?.code === 200) {
          if (responseJson.response.KYCDetails.aadhaarVerified) {
            dispatch(
              payActions.updateAadhaarVerification({ aadhaarVerified: true })
            );
          }

          if (responseJson.response.KYCDetails._PANVerified) {
            dispatch(payActions.updatePANVerification({ panVerified: true }));
          }

          if (
            responseJson.response.KYCDetails.aadhaarVerified &&
            responseJson.response.KYCDetails._PANVerified
          ) {
            dispatch(payActions.updateKYCVerification({ kycVerified: true }));
          }

          return responseJson.response;
        } else if (responseJson?.code === 400) {
          dispatch(
            payActions.updateAadhaarVerification({ aadhaarVerified: false })
          );

          dispatch(payActions.updatePANVerification({ panVerified: false }));

          dispatch(payActions.updateKYCVerification({ kycVerified: false }));
          return 400;
        } else if (responseJson?.code !== 200) {
          const message = responseJson.message;
          toast.error(message);
          throw new Error(message);
        }
      } catch (error) {
        //
        toast.error("Something went wrong.");
        throw new Error(error);
      }
    },
  });

  //

  useEffect(() => {
    dispatch(payActions.updateKYCRefetchGlobal({ globalRefetch: refetch }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    // if CUStom data is not  available
    if (!_.isEmpty(currentUser)) {
      //
      // 
      //   "has reload",
      //   _.has(currentUser.reloadUserInfo, "customAttributes")
      // );
      if (_.has(currentUser, "reloadUserInfo")) {
        if (_.has(currentUser.reloadUserInfo, "customAttributes")) {
          dispatch(payActions.updateProfileStatus({ profileStatus: true }));
        } else {
          dispatch(payActions.updateProfileStatus({ profileStatus: false }));
        }
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  //
  useEffect(() => {
    setPath(_.includes(location.pathname.split("/"), "profile"));
  }, [location]);
  //

  // : userblockstate ? (
  //   <Navigate to="/ipblock" />
  // )
  return (
    <>
      {currentUser ? (
        status === "loading" ? (
          <Loader />
        ) : status === "error" ? (
          data === 400 ? (
            path ? (
              <Suspense fallback={<Loader />}>
                <Component />
              </Suspense>
            ) : (
              <Navigate to="/profile/kyc-verification" />
            )
          ) : (
            <Navigate to="" />
          )
        ) : (isProfileComplete && kycVerified) || path ? (
          <Suspense fallback={<Loader />}>
            <Component />
          </Suspense>
        ) : (
          <Navigate to="/profile/kyc-verification" />
        )
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

const PrivateRouteForHomePage = ({ component: Component }) => {
  // const [path, setPath] = useState();
  // const [isLoading, setIsLoading] = useState();
  // const { isProfileComplete, kycVerified, panVerified, aadhaarVerified } =
  //   useSelector((state) => state.pay);
  // const location = useLocation();
  const { currentUser, getAccessToken } = useAuth();
  // const { KYCRefetch } = useSelector((state) => state.pay);
  // const [statusCode, setStatusCode] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const userblockstate = useSelector((state) => state.userData.UserBlockIp);
  const { refetch, data } = useQuery({
    queryKey: ["kycDetailsGlobalKey"],
    queryFn: async () => {
      try {
        if (!currentUser) {
          return;
        }
        const token = await getAccessToken();
        const response = await fetch(APIUrls.fetchKYCDetails, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const responseJson = await response.json();
        //
        // setStatusCode(responseJson?.code);
        if (_.isEmpty(responseJson)) {
          toast.error("Something went wrong !!");
          throw new Error("Something went wrong !!");
        } else if (responseJson?.code === 200) {
          if (responseJson.response.KYCDetails.aadhaarVerified) {
            dispatch(
              payActions.updateAadhaarVerification({ aadhaarVerified: true })
            );
          }

          if (responseJson.response.KYCDetails._PANVerified) {
            dispatch(payActions.updatePANVerification({ panVerified: true }));
          }

          if (
            responseJson.response.KYCDetails.aadhaarVerified &&
            responseJson.response.KYCDetails._PANVerified
          ) {
            dispatch(payActions.updateKYCVerification({ kycVerified: true }));
          }

          return responseJson.response;
        } else if (responseJson?.code === 400) {
          dispatch(
            payActions.updateAadhaarVerification({ aadhaarVerified: false })
          );

          dispatch(payActions.updatePANVerification({ panVerified: false }));

          dispatch(payActions.updateKYCVerification({ kycVerified: false }));
          return 400;
        } else if (responseJson?.code !== 200) {
          const message = responseJson.message;
          toast.error(message);
          throw new Error(message);
        }
      } catch (error) {
        //
        toast.error("Something went wrong.");
        throw new Error(error);
      }
    },
  });

  //

  useEffect(() => {
    dispatch(payActions.updateKYCRefetchGlobal({ globalRefetch: refetch }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    // if CUStom data is not  available
    if (!_.isEmpty(currentUser)) {
      //
      // 
      //   "has reload",
      //   _.has(currentUser.reloadUserInfo, "customAttributes")
      // );
      if (_.has(currentUser, "reloadUserInfo")) {
        if (_.has(currentUser.reloadUserInfo, "customAttributes")) {
          dispatch(payActions.updateProfileStatus({ profileStatus: true }));
        } else {
          dispatch(payActions.updateProfileStatus({ profileStatus: false }));
        }
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  //
  // useEffect(() => {
  //   setPath(_.includes(location.pathname.split("/"), "profile"));
  // }, [location]);
  //

  // 

  let kyc_details = data?.KYCDetails;

  return (
    <>
      {/* {currentUser && ( */}
      <Suspense fallback={<Loader />}>
        <Component kyc_details={kyc_details} />
      </Suspense>
      {/* )} */}
    </>
  );
};

const PrivateRouteForBBPS = ({ component: Component }) => {
  const { currentUser, getAccessToken } = useAuth();

  const dispatch = useDispatch();

  const { data, status } = useQuery({
    queryKey: ["isUserExistKey"],

    queryFn: async () => {
      try {
        const token = await getAccessToken();
        const response = await axios.get(APIUrls.isUserExist, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const status = response?.data?.code;
        const data = response?.data?.response;
        if (status === 200) {
          if (data?.EmailVerifiy === true) {
            dispatch(userActions.updateEmailVerified({ emailVerified: true }));
          }

          if (data?.PaymentDisabled === false) {
            dispatch(
              userActions.updatePaymentDisabled({ paymentDisabled: false })
            );
          }

          return response?.data;
        }
      } catch (error) {
        // 

        const response = error?.response?.data;

        // open the registraction form
        if (response?.code === 400) {
          return response;
        }
        toast.error("Something went wrong.");
      }
    },
  });
  // 
 

  // THIS LOGIC HANDLE OUR LOGIN AND HOMEPAHE ROUTING , WHEN USER IS NULL IT WILL CHECKOUT TO LOGOUT , ELSE WHEN USER IS THERE ,IT NAVIGATES TO H0ME PAGE
  return (
    <>
      {currentUser ? (
        status === "loading" ? (
          <Loader />
        ) : status === "success" ? (
          data.code === 200 ? (
            data.response.EmailVerifiy ? (
              <Suspense fallback={<Loader />}>
                <Component />
              </Suspense>
            ) : (
              <Navigate to="/" />
            )
          ) : (
            <Navigate to="" />
          )
        ) : (
          <Navigate to="/" />
        )
      ) : (
        <Navigate to="/login" replace />
      )}

      {/* {currentUser ? (
        <Suspense fallback={<Loader />}>
          <Component />
        </Suspense>
      ) : (
        <Navigate to="/login" replace />
      )} */}
    </>
  );
};


// THIS IS THE REACT ROUTER AND ALL THE ROUTINGS DEFINED BELOW 

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <MainLayout />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<Loader />}>
            <LandingPage />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: <HomeLayout />,
            children: [
              {
                path: "",
                element: <PrivateRouteForHomePage component={HomePage} />,
              },
              {
                path: "/bbpsServices",
                element: <BBPSServices />,
              },
              {
                path: "*",
                element: <ComingSoon />,
              },
            ],
          },

          {
            path: "",
            element: (
              <Suspense fallback={<Loader />}>
                <CategorySelectionCard />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: <RentCards />,
                children: [
                  {
                    path: "/payout",
                    element: <PrivateRoute component={PaymentWrapper} />,
                    children: [
                      {
                        path: ":rent",
                        element: <PrivateRoute component={PaymentCard} />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "/education",
                element: <EdutionCards />,
                children: [
                  {
                    path: ":fee",
                    element: (
                      <PrivateRoute component={PaymentCardForEducation} />
                    ),
                  },
                ],
              },
              {
                path: "/bbps/utility",
                element: <UtilityCard />,
                children: [
                  {
                    path: "",
                    // element: <PrivateRoute component={PaymentCard} />,
                    element: <PrivateRouteForBBPS component={UtilityWrapper} />,
                    // element: <ComingSoon />,
                    children: [
                      {
                        path: "Electricity",
                        element: (
                          <PrivateRouteForBBPS component={ElectricityService} />
                        ),
                      },
                      {
                        path: "Water",
                        // element: (
                        //   <PrivateRouteForBBPS
                        //     component={() => (
                        //       <WaterService utilitycategory="Water" />
                        //     )}
                        //   />
                        // ),
                        element: <ComingSoon />,
                      },
                      {
                        path: "LPG_Gas",
                        element: (
                          <PrivateRouteForBBPS
                            component={() => (
                              <LPGGasService utilitycategory="LPG Gas" />
                            )}
                          />
                        ),
                      },
                      {
                        path: "BBPSEducation",
                        element: (
                          <PrivateRouteForBBPS
                            component={() => (
                              <BBPSEducation utilitycategory="Education Fees" />
                            )}
                          />
                        ),
                      },

                      {
                        path: "*",
                        element: <ComingSoon />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "/bbps/recharge",
                element: <Recharge />,
                children: [
                  {
                    path: "",
                    // element: <PrivateRoute component={PaymentCard} />,
                    element: <PrivateRouteForBBPS component={UtilityWrapper} />,
                    // element: <ComingSoon />,
                    children: [
                      {
                        path: "Mobile_Postpaid",
                        // element: (
                        //   <PrivateRouteForBBPS
                        //     component={() => (
                        //       <MobilePostpaidService utilitycategory="Mobile Postpaid" />
                        //     )}
                        //   />
                        // ),
                        element: <ComingSoon />,
                      },
                      {
                        path: "Fastag",
                        element: (
                          <PrivateRouteForBBPS
                            component={() => (
                              <FasTagService utilitycategory="Fastag" />
                            )}
                          />
                        ),
                      },
                      {
                        path: "NCMC_Recharge",
                        element: (
                          <PrivateRouteForBBPS
                            component={() => (
                              <NCMCRecharge utilitycategory="NCMC Recharge" />
                            )}
                          />
                        ),
                      },
                      {
                        path: "*",
                        element: <ComingSoon />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "/bbps/finance",
                element: <Finance />,
                children: [
                  {
                    path: "",
                    element: <PrivateRouteForBBPS component={UtilityWrapper} />,
                    // element: <ComingSoon />,
                    children: [
                      {
                        path: "Credit_Card",
                        element: (
                          <PrivateRouteForBBPS
                            component={() => (
                              <CreditCardService utilitycategory="Credit Card" />
                            )}
                          />
                        ),
                      },
                      {
                        path: "Insurance",
                        element: (
                          <PrivateRouteForBBPS
                            component={() => (
                              <InsuranceService utilitycategory="Insurance" />
                            )}
                          />
                        ),

                        // element: <ComingSoon />,
                      },
                      {
                        path: "Loan_Repayment",
                        element: (
                          <PrivateRouteForBBPS
                            component={() => (
                              <LoanRepaymentService utilitycategory="Loan Repayment" />
                            )}
                          />
                        ),
                      },
                      {
                        path: "*",
                        element: <ComingSoon />,
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            path: "login",
            element: (
              <Suspense fallback={<Loader />}>
                <LoginWrapper />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<Loader />}>
                    <Login />
                  </Suspense>
                ),
              },
              {
                path: "submit-otp",
                element: (
                  <Suspense fallback={<Loader />}>
                    <LoginOTPSubmit />
                  </Suspense>
                ),
              },
            ],
          },

          {
            path: "payout",
            element: <PrivateRoute component={PaymentWrapper} />,
            children: [
              {
                path: ":rent",
                element: <PrivateRoute component={PaymentCard} />,
              },
              {
                path: "utility/:utils",
                element: (
                  <Suspense fallback={<Loader />}>
                    <PaymentCardUtilsimport />
                  </Suspense>
                ),
              },
              {
                path: "comingsoon",
                element: (
                  <Suspense fallback={<Loader />}>
                    <ComingSoon />
                  </Suspense>
                ),
              },
              {
                path: "comingsoons",
                element: (
                  <Suspense fallback={<Loader />}>
                    <ComePage />
                  </Suspense>
                ),
              },
            ],
          },

          // {
          //   path: "payout",
          //   element: <PrivateRoute component={PaymentWrapper} />,
          //   children: [
          //     {
          //       path: "rent",
          //       element: <PaymentRentWrapper />,
          //       children: [
          //         {
          //           path: ":rent",
          //           element: <PrivateRoute component={PaymentCard} />,
          //         },
          //       ],
          //     },

          //     {
          //       path: "utility",
          //       element: <div>hello</div>,
          //       children: [
          //         {
          //           path: ":utils",
          //           element: <PrivateRoute component={PaymentCard} />,
          //         },
          //       ],
          //     },

          //     // {
          //     //   path: "utility/:utils",
          //     //   element: (
          //     //     <Suspense fallback={<Loader />}>
          //     //       <PaymentCardUtilsimport />
          //     //     </Suspense>
          //     //   ),
          //     // },

          //     {
          //       path: "comingsoon",
          //       element: (
          //         <Suspense fallback={<Loader />}>
          //           <ComingSoon />
          //         </Suspense>
          //       ),
          //     },
          //     {
          //       path: "comingsoons",
          //       element: (
          //         <Suspense fallback={<Loader />}>
          //           <ComePage />
          //         </Suspense>
          //       ),
          //     },
          //   ],
          // },

          {
            path: "paymentverify/:orderkeyid",
            element: <PrivateRoute component={PaymentVErifyWrapper} />,
          },
          {
            path: "paymentverify/bbps/:orderkeyid/",
            element: <PrivateRoute component={PaymentVErifyWrapperForBBPS} />,
          },

          {
            path: "success",
            element: <PrivateRoute component={PaymentSuccess} />,
          },
          {
            path: "failed",
            element: <PrivateRoute component={PaymentFailure} />,
          },
        ],
      },
      {
        path: "profile",
        element: <PrivateRoute component={MainProfilePage} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={MyProfile} />,
          },
          {
            path: "my-transactions",
            element: <PrivateRoute component={Mytransaction} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={AllTransactions} />,
              },
              {
                path: "house-rent",
                element: <PrivateRoute component={HouseRentTransactions} />,
              },
              {
                path: "office-rent",
                element: <PrivateRoute component={OfficeRentTransactions} />,
              },
              {
                path: "maintenance",
                element: <PrivateRoute component={MaintenanceTransactions} />,
              },
              {
                path: "education-fee",
                element: <PrivateRoute component={EducationFeeTransactions} />,
              },
            ],
          },
          {
            path: "kyc-verification",
            element: <PrivateRoute component={KYCVerification} />,
          },
          {
            path: "beneficiary",
            element: <PrivateRoute component={Beneficiary} />,
          },
        ],
      },
      {
        path: "referral",
        element: <PrivateRoute component={Referralmainpage} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={ReferralProgram} />,
          },
          {
            path: "earnings",
            element: <PrivateRoute component={Earnings} />,
          },
        ],
      },

      {
        path: "terms-and-conditions",
        element: (
          <Suspense fallback={<Loader />}>
            <Terms />
          </Suspense>
        ),
      },
      {
        path: "about-us",
        element: (
          <Suspense fallback={<Loader />}>
            <AboutUs />
          </Suspense>
        ),
      },
      {
        path: "privacy-policy",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "refund-policy",
        element: (
          <Suspense fallback={<Loader />}>
            <RefundPolicy />
          </Suspense>
        ),
      },
      {
        path: "prepaid-card/terms-and-conditions",
        element: (
          <Suspense fallback={<Loader />}>
            <PrepaidCars />
          </Suspense>
        ),
      },
      {
        path: "contact-us",
        element: (
          <Suspense fallback={<Loader />}>
            <Contactus />
          </Suspense>
        ),
      },
      {
        path: "/ipblock",
        element: <Ipblocklayout />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
]);
