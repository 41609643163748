import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../stores/AuthContext";


// AS RENT PAYMENT WRAPPER UTILITIES ALSO HAVE ONE WRAPPER CARD IN THE OUTER SECTION WHERE ALL OTHER COMPONENT ARE WRAPPED IN 
function UtilityWrapper(props) {
  const { currentUser } = useAuth();
  const location = useLocation();

  // 
  const navigate = useNavigate();

  // CURRENTLY THSE SERVICES ARE LIVE 
  useEffect(() => {
    if (
      currentUser &&
      (location.pathname.includes("/bbps/utility/Electricity") ||
        location.pathname === "/bbps/utility")
    ) {
      navigate("/bbps/utility/Electricity");
    } else if (
      currentUser &&
      (location.pathname.includes("/bbps/recharge/Fastag") ||
        location.pathname === "/bbps/recharge")
    ) {
      navigate("/bbps/recharge/Fastag");
    } else if (
      currentUser &&
      (location.pathname.includes("/bbps/finance/Credit_Card") ||
        location.pathname === "/bbps/finance")
    ) {
      navigate("/bbps/finance/Credit_Card");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <div>
      <Helmet>
        <title>Utility Payment | Payinstacard</title>
      </Helmet>
      <Outlet />
    </div>
  );
}

export default UtilityWrapper;
