import React from "react";
import { Outlet } from "react-router-dom";

// THIS IS USED INSIDE THE ROUTE OF LANDINGPAGE FOR NESTING THE COMPONENTS
function HomeLayout(props) {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default HomeLayout;
