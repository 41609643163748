/**Top 10 bank of india */
import axis from "../../src/assets/revamped/bank/Axis.svg";
import bankOfBaroda from "../../src/assets/revamped/bank/bankOfBaroda.svg";
import dummy from "../../src/assets/revamped/bank/dummy.png";
import hdfc from "../../src/assets/revamped/bank/HDFCBank.svg";
import icici from "../../src/assets/revamped/bank/ICICI Bank.svg";
import indianOverseasBank from "../../src/assets/revamped/bank/indianOverseasBank.svg";
import indusIndBank from "../../src/assets/revamped/bank/indusIndBank.svg";
import kotak from "../../src/assets/revamped/bank/kotak.svg";
import punjabNation from "../../src/assets/revamped/bank/punjabNation.svg";
import sbi from "../../src/assets/revamped/bank/Sbi.svg";
import unionBank from "../../src/assets/revamped/bank/unionBank.svg";

import americanExpress from "../../src/assets/revamped/bank/americanExpress.svg";
import andhraBank from "../../src/assets/revamped/bank/andhraBank.svg";
import aUSmallFinanceBank from "../../src/assets/revamped/bank/aUSmallFinanceBank.svg";
import bankofAmerica from "../../src/assets/revamped/bank/bankofAmerica.svg";
import bankofIndia from "../../src/assets/revamped/bank/bankofIndia.svg";
import canaraBank from "../../src/assets/revamped/bank/canaraBank.svg";
import centalBankofIndia from "../../src/assets/revamped/bank/centalBankofIndia.svg";
import citibank from "../../src/assets/revamped/bank/citibank.svg";
import corporationBank from "../../src/assets/revamped/bank/corporationBank.svg";
import DCB from "../../src/assets/revamped/bank/DCB.svg";
import dhanalakshmiBank from "../../src/assets/revamped/bank/dhanalakshmiBank.svg";
import federalBank from "../../src/assets/revamped/bank/federalBank.svg";
import HSBCBank from "../../src/assets/revamped/bank/HSBCBank.svg";
import IDBIBank from "../../src/assets/revamped/bank/IDBIBank.svg";
import IDFCFirstBank from "../../src/assets/revamped/bank/IDFCFirstBank.svg";
import indianBank from "../../src/assets/revamped/bank/indianBank.svg";
import jammuandKashmirBank from "../../src/assets/revamped/bank/jammuandKashmirBank.svg";
import RBLBank from "../../src/assets/revamped/bank/RBLBank.svg";
import standardCharteredBank from "../../src/assets/revamped/bank/standardCharteredBank.svg";
import syndicateBank from "../../src/assets/revamped/bank/syndicateBank.svg";
import vijayaBank from "../../src/assets/revamped/bank/vijayaBank.svg";
import yesBank from "../../src/assets/revamped/bank/yesBank.svg";

const bankLogo = [
  {
    id: 1,
    ifsc: "HDFC",
    logo: hdfc,
  },
  {
    id: 2,
    ifsc: "ICIC",
    logo: icici,
  },
  {
    id: 3,
    ifsc: "SBIN",
    logo: sbi,
  },
  {
    id: 4,
    ifsc: "KKBK",
    logo: kotak,
  },
  {
    id: 5,
    ifsc: " UTIB",
    logo: axis,
  },
  {
    id: 6,
    ifsc: "PUNB",
    logo: punjabNation,
  },
  {
    id: 7,
    ifsc: "BARB",
    logo: bankOfBaroda,
  },
  {
    id: 8,
    ifsc: "IOBA",
    logo: indianOverseasBank,
  },
  {
    id: 9,
    ifsc: "INDB",
    logo: indusIndBank,
  },
  {
    id: 10,
    ifsc: "UBIN",
    logo: unionBank,
  },
  {
    id: 11,
    ifsc: "SCBL",
    logo: americanExpress,
  },
  {
    id: 12,
    ifsc: "ANDB",
    logo: andhraBank,
  },
  {
    id: 13,
    ifsc: "AUBL",
    logo: aUSmallFinanceBank,
  },
  {
    id: 14,
    ifsc: "BOFA",
    logo: bankofAmerica,
  },
  {
    id: 15,
    ifsc: "BKID",
    logo: bankofIndia,
  },
  {
    id: 16,
    ifsc: "CNRB",
    logo: canaraBank,
  },
  {
    id: 17,
    ifsc: "CBIN",
    logo: centalBankofIndia,
  },
  {
    id: 18,
    ifsc: "CITI",
    logo: citibank,
  },
  {
    id: 19,
    ifsc: "CORP",
    logo: corporationBank,
  },
  {
    id: 20,
    ifsc: "DCBL",
    logo: DCB,
  },
  {
    id: 21,
    ifsc: "DLXB",
    logo: dhanalakshmiBank,
  },
  {
    id: 22,
    ifsc: "FDRL",
    logo: federalBank,
  },
  {
    id: 23,
    ifsc: "HSBC",
    logo: HSBCBank,
  },
  {
    id: 24,
    ifsc: "IBKL",
    logo: IDBIBank,
  },
  {
    id: 25,
    ifsc: "IDFB",
    logo: IDFCFirstBank,
  },
  {
    id: 26,
    ifsc: "IDIB",
    logo: indianBank,
  },
  {
    id: 27,
    ifsc: "JAKA",
    logo: jammuandKashmirBank,
  },
  {
    id: 28,
    ifsc: "RATN",
    logo: RBLBank,
  },
  {
    id: 29,
    ifsc: "SCBL",
    logo: standardCharteredBank,
  },
  {
    id: 30,
    ifsc: "SYNB",
    logo: syndicateBank,
  },
  {
    id: 31,
    ifsc: "VIJB",
    logo: vijayaBank,
  },
  {
    id: 32,
    ifsc: "YESB",
    logo: yesBank,
  },
];


/**
 * Function to get the logo of the bank based on IFSC code
 * @param {string} ifsc - The IFSC code of the bank
 * @returns {string} - The logo of the bank or a dummy image if not found
 */

const getBankLogo = (ifsc) => {
  const bank = bankLogo.find((bank) => bank.ifsc === ifsc);
  return bank ? bank.logo : dummy;
};

export default getBankLogo;
