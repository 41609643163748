import { createSlice } from "@reduxjs/toolkit";

// Initial state for the slice
const initialState = {
  alltransactions: [], // Array to hold all transaction data
  transactionOrder: "Newest", // Order of transactions to display, default is "Newest"
};

// Create a slice of the Redux store
export const alltransactionSlice = createSlice({
  name: "allTransaction", // Name of the slice
  initialState, // Initial state defined above
  reducers: {
    // Action to store transactions in the state
    storeTransaction(state, action) {
      state.alltransactions = action.payload.alltransactions; // Update the transactions in the state
    },
    // Action to set the order of transactions
    setTransactionOrder(state, action) {
      state.transactionOrder = action.payload.transactionOrder; // Update the transaction order in the state
    },
  },
});
