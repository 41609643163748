// eslint-disable-next-line no-unused-vars
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCazUjzmtDEDtT2MD7JvsfEFmWtGBuIvB8",
  authDomain: "pic-migration-instance-prod.firebaseapp.com",
  projectId: "pic-migration-instance-prod",
  storageBucket: "pic-migration-instance-prod.appspot.com",
  messagingSenderId: "1014565268166",
  appId: "1:1014565268166:web:bf3432231014af299cc5c7",
  measurementId: "G-2CWG38HV5K"
};
const app = initializeApp(firebaseConfig);
////
export const auth = getAuth(app);
// const analytics = getAnalytics(app);
export default app;
