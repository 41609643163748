let baseUrl = "http://localhost:4000";
let cashfreeMode = "sandbox";
let ReturnUrl = "http://localhost:3000";
const type = "STAGING";

if (type === "PROD") {
  baseUrl = "https://apiv2.payinstacard.com";
  ReturnUrl = "https://payinstacard.com";
  cashfreeMode = "production";
} else if (type === "STAGING") {
  baseUrl = "https://stagingapiv2.payinstacard.com";
  ReturnUrl = "https://staging.payinstacard.com";
  cashfreeMode = "production"; 

  // baseUrl = "https://payinstacard-staging-deployments-stagemainbackend.zjpgzy.easypanel.host";
  // ReturnUrl = "https://staging.payinstacard.com";
  // cashfreeMode = "production";
} else {
  baseUrl = "http://localhost:4000";
}

export const APIUrls = {
  ENV_TYPE: type, // enviroment type DEV or PROD
  paymentENV: "PROD", // PAYMENT ENVIROMENT
  baseUrl: baseUrl, // BASE URL SET CURRENTLY
  updateProfile: `${baseUrl}/users/profile-update`, // NOT IN USE 
  generateAadhaarOTP: `${baseUrl}/verification-suits/generateAadhaarOTP`, // NOt iN USE
   submitAadhaarOTP: `${baseUrl}/verification-suits/submitAadhaarOTP`, // NOT IN USE
  verifyPAN: `${baseUrl}/verification-suits/verifyPAN`, // NOT IN USE
  //add_benificer: `${baseUrl}/users/add-new-ben`, // NOT in use
  add_benificer: `${baseUrl}/users/add-cashfree-ben`, // to add benificary using cashfree 
 fetchBen: (category) =>
    `${baseUrl}/users/fetch-beneficiary?ben_category=${category}`, // fetch benificary 
  make_payment: `${baseUrl}/payment/create_order_post`, // to create order in PG not in use
  fetch_order: `${baseUrl}/payment/order_detail`, // fetch order details not in use
  deleteBenficiery: `${baseUrl}/users/remove-ben-new`, // to remove a benificary 
  fetchKYCDetails: `${baseUrl}/users/fetch-KYCDetails`, // to fetch kyc details 
  requestKYC: `${baseUrl}/users/kyc-request`, // to get kyc request if from digio 
  requestVerifyKYC: `${baseUrl}/users/kyc-request-verify/`, // to verify that id from digio 
  getTransactions: `${baseUrl}/users/get-transactions`, // get users transacions
  airpay_payment: `${baseUrl}/payment/airpay_post`, // not in use
  airpay_verify: `${baseUrl}/payment/airpay/verify-order`, // not in use 
  login_logs: `${baseUrl}/users/update-login-log`, // to record the logins 
  get_persentage: `${baseUrl}/users/get-commission`, // get convie fees of current user 
  set_feedback: `${baseUrl}/users/user-feedback`, // post users feedback 
  email_verify: `${baseUrl}/users/verify-email`, // to verify mail 
  submit_otp_email: `${baseUrl}/users/email-otp-submit`, // to submit email otp 
  login_blocker: `${baseUrl}/users/login-prevention`, // to check ip and block users outside india
  log_visitors: `${baseUrl}/users/visitor/logs`, // log visitors records 
  get_wallet_limit: `${baseUrl}/api/v2/dashboarding/get-wallet-limit`, // get wallet limit for a user
  get_userData: `${baseUrl}/users/get/data`, //  get all data for users to display
  validate_referals: `${baseUrl}/referal/validatereferal`, // validate refferal code before login
  generate_referals: `${baseUrl}/referal/getreferaid`, // generate refferal id in refferal page
  contactus_post: `${baseUrl}/users/contactus`, // this is for contact us form submit
  withdrawal_request: `${baseUrl}/referal/request-widthdrawal`, // this is used to request withdrawals 
  get_settingsData: `${baseUrl}/users/get-settings`, // this is used to get payment settings from admin
  biller_fetch: `${baseUrl}/api/bbps/fetchbillers`, // this is used to fetch the bill of consumer for BBPS 
  fetch_biller_states: `${baseUrl}/api/bbps/fetchBillerStates`, // this is to fetch all the states of bbps available
  fetch_bills: `${baseUrl}/api/bbps/fetch-bill`, // fetch bills of bbps 
  validate_bills: `${baseUrl}/api/bbps/validate-bill`, // validate bills of bbps 
  razorPayroll: `${baseUrl}/paymentg/razorpay/create-order`, // this is not in use
  paymentStatus2: `${baseUrl}/paymentg/razorpay/status`, //  this is not in use
  cashFreeCreateOrder: `${baseUrl}/paymentg/cashfree/create-order`, // this is currently used in rent payment
  cashFreePaymentStatus: `${baseUrl}/paymentg/cashfree/status`, // to fetch the order status 
  cashFreePaymentResponse: `${baseUrl}/paymentg/cashfree/payment_response`, // to get the payment response for recipets
  ReturnUrl: ReturnUrl, // return url of cashfree sdk
  cashfreeMode: cashfreeMode, // payment mode of cashfree
  cashFreeCreateOrderForBBPS: `${baseUrl}/api/bbps/cashfree/create-order`, // create order api for bbps
  cashFreePaymentStatusForBBPS: `${baseUrl}/api/bbps/cashfree/status`, // fetch order status for bbps
  cashFreePaymentResponseForBBPS: `${baseUrl}/api/bbps/cashfree/payment_response`, // generate reciepts for bbps
  KYC_verify: `${baseUrl}/users/event/`, // this is a realtime SSE api which updates status of kyc in realtime 
  getTransactionDetail: `${baseUrl}/paymentg/getTransactionDetail`, // get transaction details by order id 
  createUser: `${baseUrl}/users/create-user`, // create user when login first time using name and email
  isUserExist: `${baseUrl}/users/is-user-exist`, // check if the user exist in the database or not then show the model
  auth:{
    sendPhoneAuthOTP:`${baseUrl}/api/v2/auth/phone/send-otp`, // send otp from MSG(91)
    resendPhoneAuthOTP:`${baseUrl}/api/v2/auth/phone/resend-otp`, // resend otp from MSG(91)
    verifyPhoneAuthOTP:`${baseUrl}/api/v2/auth/phone/verify-otp` // VERIFY otp FROM MSG(91)
  }
};
