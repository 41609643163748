import { Icon } from "@iconify/react";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import cross from "../../../src/assets/revamped/Cross.svg";
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";
import { fetchUser } from "../../stores/UserRedux";
import Modal from "./Modal";




// THIS COMPONENT CONSIST OF THE MODAL FOR SENDING AND VERIFY EMAIL OTP IN HOME PAGE , SAME AS EMAIL OTP

// EXTRACT REACT FORM DATA FROM REACT FORM HOOK
const EmailOTPInHomePage = ({
  isOpenModal,
  setIsOpenModal,
  otp_uuid,
  email,
  SendVerify,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [isFirstOpen, setIsFirstOpen] = useState(true);

  const { getAccessToken, updateUsers } = useAuth();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [isOtpError, setOtpError] = useState(false);
  const navigate = useNavigate();
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const { logout, currentUser } = useAuth();
  const navigation = useNavigate();

  const [timer, setTimer] = useState(60);

  const {
    // register,
    // handleSubmit,
    formState: {
      //  errors,
      isValid,
    },
    // control,
    // reset,
  } = useForm();

  const submitOTPs = async () => {
    //

    if (_.isEmpty(otp)) {
      return displayError("OTP is required");
    }

    try {
      setIsLoading(true);

      const token = await getAccessToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .post(
          APIUrls.submit_otp_email,
          {
            otp,
            uu_id: otp_uuid,
          },
          config
        )
        .then(async (data) => {
          setIsLoading(false);
          const { code, message } = data.data;

          if (data.status === 200) {
            if (code === 200) {
              setIsOpenModal(false);

              toast.success("Email has been verified successfully");

              await updateUsers();

              dispatch(fetchUser(token));

              return navigate("/");
            }
          }

          if (data.status === 400) {
            if (code === 400) {
              displayError(message);
              return toast.error(message);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          // setIsOpenModal(false);
          return toast.error("Something went wrong Please try again later !");
        });
    } catch (errr) {
      setIsLoading(false);

      // setIsOpenModal(false);
      return toast.error("Something went wrong Please try again later !");
    }
  };

  let individualInputStyle = {
    width: "54px",
    height: "50px",
    fontSize: "19px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",

    outline: "none",
    margin: "auto",
    fontStyle: "bold",
    background: "#202020",
    color: "white",
  };
  const handleOtpChange = (e) => {
    const value = e.target.value;

    // Basic validation example: checking if the OTP is a 6-digit number
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
      displayError("");
      if (value.length !== 6) {
        displayError("OTP must be exactly 6 digits");
      } else {
        displayError(""); // Clear message if validation passes
      }
    } else {
      displayError("OTP must be a 6-digit number");
    }
  };

  const displayError = (text) => {
    setOtpErrorMsg(text);
    setOtpError(true);
  };
  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal, timer]);
  const logoutHandler = async (e) => {
    e.preventDefault();
    await logout();
    navigation("/");
  };

  return (
    <div className="fixed bg-black bg-opacity-[0.4] left-0 right-0 bottom-0 top-0 z-30">
      <div className="fixed  left-1/2 top-1/2   transform -translate-x-1/2 -translate-y-1/2 z-50">
        <div className="w-[300px] relative xs:w-[500px]  bg-[#202020] rounded px-7 py-7 xs:px-10 xs:py-14  flex flex-col gap-[1rem] relative  overflow-y-auto">
          <div className="flex flex-row justify-center w-full">
            <form
              // action=""
              // onSubmit={handleSubmit(addBenificer)}
              className=" w-full "
            >
              <div className="mb-[10px] xs:mb-[20px]">
                <label
                  htmlFor="beneficiary_phone"
                  className="mb-[7px] block text-[rgba(255,_255,_255,_0.70)] font-[Inter]  not-italic font-medium leading-[normal] "
                >
                  Enter your 6 digit OTP
                </label>

                <input
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  className=" w-full px-2 py-1 xs:px-4 xs:py-2 text-[#ffffff] bg-transparent font-bold text-[0.8rem] xs:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                />
                {isOtpError && (
                  <span className="text-[#F00] text-[15px] xs:text-[16px] mt-[-10px]">
                    {isOtpError ? otpErrorMsg : ""}
                  </span>
                )}
              </div>
              <div className="flex mb-[10px] xs:mb-[20px] ">
                {timer > 0 ? (
                  <p className="text-[#FFF] font-[Inter] text-[14px] md:text-[16px] not-italic font-semibold leading-[normal] [text-decoration-line:underline]">
                    Resend OTP in {timer} sec{" "}
                  </p>
                ) : (
                  <button
                    type="button"
                    className="text-[#FFF] text-[0.8rem] xs:text-[1rem] font-bold"
                    onClick={(e) => {
                      SendVerify(e);
                    }}
                  >
                    Resend OTP
                  </button>
                )}
              </div>

              <div className="flex justify-center">
                <button
                  type="button"
                  disabled={isLoading}
                  className={`${
                    !isValid || isLoading
                      ? "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                      : "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                  } text-center w-[200px] xs:w-full  rounded-[8px] text-white px-[2rem] py-[0.4rem] xs:py-[0.7rem] xs:px-[6rem] font-[800] flex flex-row items-center  justify-center gap-[0.6rem]`}
                  onClick={() => submitOTPs()}
                >
                  {isLoading && (
                    <CgSpinner
                      size={`${window.innerWidth < 768 ? 10 : 20}`}
                      className="mt-1 animate-spin"
                    />
                  )}
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div
            onClick={logoutHandler}
            className="absolute right-[20px] top-[10px] xs:right-[32px] xs:top-[15px] cursor-pointer flex rounded-full  items-center gap-2 p-[4px] xs:p-2 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)] "
          >
            <h3
              className="text-white text-[14px] xs:text-[16px] font-medium"
              type="button"
            >
              Logout
            </h3>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default EmailOTPInHomePage;
