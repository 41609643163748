/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";

import { PDFDownloadLink } from "@react-pdf/renderer";
import CardWrapper from "./../CardWrapper";

import successImage from "../../../assets/revamped/bbps-assured 1.svg";
import PdfRecieptForBBPS from "../../utils/PdfRecieptForBBPS";


// This component consist of the success page of BBPS after payment is success


const BBPSPaymentSuccess = (props) => {
  // const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();
  const goBack = (e) => {
    e.preventDefault();
    navigate("/profile/my-transactions");
  };

  //   const addFeedback = async () => {
  //     //console.log("hello");

  //     setIsLoading(true);

  //     if (Ratings === 0) {
  //       setIsLoading(false);
  //       return toast.error("Please Give us atlest one Star");
  //     }

  //     if (_.isEmpty(comment)) {
  //       setIsLoading(false);
  //       return toast.error("Please write comment");
  //     }

  //     const data = {
  //       ratings: Ratings.toString(),
  //       comment,
  //     };

  //     try {
  //       const token = await getAccessToken();
  //       const response = await fetch(APIUrls.set_feedback, {
  //         method: "POST",
  //         body: JSON.stringify(data),
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       setIsLoading(false);
  //       const responseJson = await response.json();
  //       if (_.isEmpty(responseJson)) {
  //         return toast.error("Something went wrong !!");
  //       }

  //       if (responseJson?.code === 200) {
  //         // const message = responseJson.response.message;
  //         toast.success("Thank you for your feedback !");
  //         // setIsOpenModal(false);
  //         return;
  //       }

  //       if (responseJson?.code === 400) {
  //         // const message = responseJson.response.message;
  //         return toast.error("Something went Wrong ,Try again later !");
  //       }

  //       //console.log(responseJson);
  //     } catch (error) {
  //       setIsLoading(false);
  //       //console.log(error);

  //       toast.error("Something went Wrong !");
  //     }
  //   };

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setSuccessImg(success);
  //     }, 2000);
  //   }, []);
  return (
    <CardWrapper
      className={
        "px-[1rem] bg-[#F9F9F9] font-inter rounded-[12px] md:rounded-[25px] xs:max-sm:px-[1.5rem] sm:max-md:px-[2.5rem] md:px-[1rem] lg:px-[1.5rem] xl:px-[2.5rem] flex flex-col justify-center gap-[0px] sm:gap-[2rem] py-[1rem] sm:py-[2rem] min-h-[420px] xs:min-h-[500px]"
      }
    >
      {/* <p className="flex text-white flex-row gap-[1rem] justify-between items-center text-black text-[1.2rem] xs:text-[1.5rem] font-bold">
        <Icon
          icon="tabler:arrow-left"
          className="text-[1.5rem] xs:text-[2rem] cursor-pointer"
          onClick={(e) => {
            goBack(e);
          }}
        />{" "}
        <Icon
          icon="bi:question-circle"
          className="text-[1.5rem] xs:text-[2rem] cursor-pointer"
        />{" "}
      </p> */}

      <div className="flex flex-col md:flex-row  md:items-center md:justify-center px-[0px] sm:px-[40px] lg:px-[60px] xl:px-[80px] gap-[20px] md:gap-[40px] lg:gap-[60px] xl:gap-[80px] ">
        <div className="">
          <div className="w-full flex justify-center mb-[20px]">
            <img
              src={successImage}
              alt=""
              className="w-[90px] sm:w-[120px] lg:w-[150px]"
            />
          </div>

          <div className="text-center">
            <p className="text-[#00B300] text-center font-[Inter] mb-[16px] text-[16px] sm:text-[20px] xl:text-[24px] not-italic font-bold leading-[normal]">
              Your payment of ₹{props.amount || 0} was Successful.
            </p>
            <p className="text-[#FFF] mb-[14px] sm:mb-[16px] lg:mb-[32px]  font-[Inter] text-[14px] sm:text-[16px] xl:text-[20px] not-italic font-bold leading-[normal]">
              <span className="text-[#000] font-[Inter]  not-italic font-semibold leading-[normal]">
                Transaction ID:{" "}
              </span>
              <span className="text-[rgba(0,_0,_0,_0.60)]">
                {props.keyid || "N/A"}
              </span>
            </p>
          </div>
          {/* <p className="text-[#FFF] mb-[14px] sm:mb-[16px] lg:mb-[32px] flex justify-between items-center font-[Inter] text-[14px] sm:text-[16px] xl:text-[20px] not-italic font-bold leading-[normal]">
            <span className="text-[rgba(255,_255,_255,_0.70)]">
              Total Amount
            </span>
            <span className="">₹{props.amount || 0}</span>
          </p> */}
          {/* <p className="text-[#FFF] flex justify-between items-center font-[Inter] text-[14px] sm:text-[16px] xl:text-[20px] not-italic font-bold leading-[normal]">
            <span className="text-[rgba(255,_255,_255,_0.70)]">
              View Receipt
            </span>
            <PDFDownloadLink
              className={`
          `}
              document={<PdfReciept payment={props.orderData} />}
              fileName="PaymentReciept.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                ) : (
                  <Icon
                    icon="material-symbols:download-sharp"
                    width="2rem"
                    height="2rem"
                    style={{ color: "#F73367" }}
                  />
                )
              }
            </PDFDownloadLink>
          </p> */}

          {console.log('ORDERDA' , props.orderData)}

          <PDFDownloadLink
            className={`
            bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)]
           text-center  font-[Inter] w-full text-[16px] sm:text-[20pxrem] rounded-[30px] text-white py-[0.8rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]`}
            document={<PdfRecieptForBBPS payment={props.orderData} />}
            fileName="PaymentReciept.pdf"
          >
            {({ loading }) =>
              loading ? (
                <span className="text-[16px] sm:text-[20px]">
                  Loading document...
                </span>
              ) : (
                <span className="text-[16px] sm:text-[20px]">View Recipt</span>
              )
            }
          </PDFDownloadLink>
          <button
            className=" border border-[#F55960]
           text-center mt-[16px] sm:mt-[24px]  font-[Inter] w-full text-[16px] sm:text-[20pxrem] rounded-[30px] text-white py-[0.8rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]"
            onClick={(e) => goBack(e)}
          >
            <span className="text-[16px] sm:text-[20px] text-[#F55960] ">
              Home
            </span>
          </button>

          {/* </button> */}
        </div>
      </div>
    </CardWrapper>
  );
};

export default BBPSPaymentSuccess;
