import React, { useState } from "react";


// THIS COMPONENT IS TO SET THE TOTAL BILL INFO FETCHED FROM THE BACKEND
function CreditCardAmountSection({
  billInfo,
  setBillInfo,
  openEditAmount,
  closeEditAmount,
  setErrors,
}) {
  // State to keep track of the selected radio button value
  const [selectedValue, setSelectedValue] = useState(false);

  // Handle radio button change event
  const handleChange = (event) => {
    let inputValue = event.target.value;

    // Check if the "Any Amount" radio button is selected
    if (event.target.name === "Any Amount") {
      openEditAmount(); // Open the custom amount input
    } else {
      closeEditAmount(); // Close the custom amount input
      setErrors({}); // Clear any errors
    }

    setSelectedValue(event.target.value); // Update selected value state

    // Update billInfo state with the new amount
    setBillInfo((prevBillInfo) => ({
      ...prevBillInfo,
      amount: inputValue,
    }));
  };

  // Extract additionalInfo from billInfo
  const additionalInfo = billInfo?.responseData?.response?.additionalInfo;
  
  // Initialize radioButtons array
  let radioButtons = [];

  // Populate radioButtons array with data from additionalInfo
  additionalInfo?.find((obj) => {
    let outStandingdata = obj.name.toLowerCase().includes("outstanding")
      ? obj
      : null;
    if (outStandingdata) {
      radioButtons.push({
        ...outStandingdata,
        label: "Current Outstanding Amount",
      });
    }

    let totalDueData = obj.name.toLowerCase().includes("total") ? obj : null;
    if (totalDueData) {
      radioButtons.push({ ...totalDueData, label: "Total Due Amount" });
    }

    let unbilleData = obj.name.toLowerCase().includes("unbilled") ? obj : null;
    if (unbilleData) {
      radioButtons.push({ ...unbilleData, label: "Unbilled Amount" });
    }

    let minimumData = obj.name.toLowerCase().includes("minimum") ? obj : null;
    if (minimumData) {
      radioButtons.push({ ...minimumData, label: "Minimum due amount" });
    }

    // Add "Any Amount" option to radioButtons array
    radioButtons.push({
      name: "AnyAmount",
      value: billInfo
        ? billInfo?.responseData?.response?.billerResponse?.amount
        : "",
      label: "Any Amount",
    });
  });

  return (
    <div>
      <h4 className="text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] xs:text-[20px] mb-5">
        How much do you want to pay?
      </h4>

      {/* Render radio buttons */}
      {radioButtons?.map((option, index) => (
        <div
          key={index}
          className="flex items-center gap-2 mb-3 text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] xs:text-[20px] "
        >
          <input
            type="radio"
            name={option.label}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
            className="text-[#f00] w-6"
            id={option.name}
          />
          <label htmlFor={option.name}>
            {option.label || option.name}
            {option.label !== "Any Amount" && (
              <span className="text-white"> - {option.value}</span>
            )}
          </label>
        </div>
      ))}
    </div>
  );
}

export default CreditCardAmountSection;
