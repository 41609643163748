import React from "react";
import { NavLink } from "react-router-dom";

// This is the component used for each cards with logo of the bbps service

function ServicesCard({ title, data }) {
  return (
    <div className=" bg-[#131313] max-w-[1568px] m-auto text-white px-[20px] py-[20px] md:px-[40px] md:py-[20px] mb-[40px] rounded-[16px] border-[1px] border-[solid] border-[rgba(255,255,255,0.04)] box-shadow:0px_0px_20px_0px_rgba(0,_0,_0,_0.10) flex flex-col items-start gap-[16px] md:gap-[32px] ">
      <p className="font-[Inter] text-[24px] not-italic font-bold leading-[normal]">
        {title}
      </p>
      <div className="flex flex-wrap items-center gap-[20px] md:gap-[40px] self-stretch justify-center	md:justify-normal">
        {data.map((item, index) => {
          return (
            <NavLink
              key={index}
              to={item.path}
              className=" flex min-w-[160px] p-[12px] flex-col items-center gap-[16px] hover:bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] rounded-[8px]"
            >
              <img src={item.logo} alt="" />
              <span className="text-center font-[Inter] text-[16px] not-italic font-medium leading-[normal]">
                {item.lable}
              </span>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}

export default ServicesCard;
