import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { paySlice } from "./PayRedux";
import { userSlice } from "./UserRedux";
import { alltransactionSlice } from "./alltransaction";

// Combine the reducers
const rootReducer = combineReducers({
  pay: paySlice.reducer,               // Reducer for pay slice
  userData: userSlice.reducer,         // Reducer for user data slice
  allTransaction: alltransactionSlice.reducer, // Reducer for all transaction slice
});

const store = configureStore({
  reducer: rootReducer,                // Set the combined reducer
  middleware: [thunk],                 // Apply Redux Thunk middleware
});

export const payActions = paySlice.actions;           // Export pay slice actions
export const userActions = userSlice.actions;         // Export user slice actions
export const allTransactionActions = alltransactionSlice.actions; // Export all transaction slice actions

export default store;                                 // Export the configured store
